<!-- 服务器 页面 主页面 -->
<template>
  <div class="server">
    <div class="neirong">
      <!-- 双向通道-竖 -->
      <div class="one pipeline">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="two pipeline">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <!-- <div class="three pipeline">
                <div class="up">
                    <div class="d_up">
                        <img src="@/assets/server/huohuohuo.png" alt="">
                    </div>
                </div>
                <div class="down">
                    <div class="d_down">
                        <img src="@/assets/server/huohuohuo.png" alt="">
                    </div>
                </div>
      </div>-->
      <div class="eight pipeline">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="nine pipeline">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <!-- <div class="ten pipeline">
                <div class="up">
                    <div class="d_up">
                        <img src="@/assets/server/huohuohuo.png" alt="">
                    </div>
                </div>
                <div class="down">
                    <div class="d_down">
                        <img src="@/assets/server/huohuohuo.png" alt="">
                    </div>
                </div>
      </div>-->
      <!-- 双向通道-斜 -->
      <div class="eleven pipeline_oblique">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="twelve pipeline_inclined">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <!-- 双向通道-横 -->
      <div class="four pipeline_across">
        <div class="up">
          <div class="d_left">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_right">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="five pipeline_across">
        <div class="up">
          <div class="d_left">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_right">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="six pipeline_across">
        <div class="up">
          <div class="d_left">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_right">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="seven pipeline_across">
        <div class="up">
          <div class="d_left">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_right">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <!-- 双向通道-斜 -->
      <div class="elevenTwo pipeline_oblique">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <div class="twelveTwo pipeline_inclined">
        <div class="up">
          <div class="d_up">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
        <div class="down">
          <div class="d_down">
            <img src="@/assets/server/huohuohuo.png" alt />
          </div>
        </div>
      </div>
      <!-- 核心服务器 -->
      <div class="kernel middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">核心网服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <kernel_ :five="core" :vessel="vessel"></kernel_>
          </div>
        </div>
      </div>
      <!-- 地图服务器 -->
      <div class="map_server middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">地图服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <mapServer :five="map" :vessel="vessel"></mapServer>
          </div>
        </div>
      </div>
      <!-- 容栽服务器 -->
      <div class="Should_the_load middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">容载服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <seatService :five="capacity" :vessel="vessel"></seatService>
          </div>
        </div>
      </div>
      <!-- 21座4G基站 -->
      <div class="base_station large_frame">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">21座1.4G基站</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <baseStation></baseStation>
          </div>
        </div>
      </div>
      <!-- 无人机网交换机 -->
      <div class="uav small_box">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">无人机网交换机</div>
          </div>
          <!-- 内容 @click="server_status(9)"-->
          <div class="database">
            <uavWang></uavWang>
          </div>
        </div>
      </div>
      <!-- 网闸 -->
      <div class="_Gatekeeper middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">网闸</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <wangzha :five="gatekeeper" :vessel="vessel"></wangzha>
          </div>
        </div>
      </div>
      <!-- 图像网交换机 -->
      <div class="exchange small_box" @click="jump">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">图像网交换机</div>
          </div>
          <!-- 内容 @click="server_status(10)"-->
          <div class="database">
            <tuxiangwnag></tuxiangwnag>
          </div>
        </div>
      </div>
      <!-- 平台服务器群 -->
      <ul class="platform fleet">
        <li class="suofang" v-for="(item, index) in jiqun" :key="index" :style="{ zoom: bei }">
          <div class="middle_bezel">
            <div class="Bjcolor">
              <div class="head">
                <div class="head_text">平台服务器</div>
              </div>
              <!-- 内容 -->
              <div class="database">
                <platformServr :five="item" :vessel="vessel"></platformServr>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- MQTT服务器 -->
      <div class="mqtt middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">MQTT服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <mqttServer :five="mtt" :vessel="vessel"></mqttServer>
          </div>
        </div>
      </div>
      <!-- 视频服务器 -->
      <div class="video middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">视频服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <videoServer :five="video" :vessel="vessel"></videoServer>
          </div>
        </div>
      </div>
      <!-- 飞控服务器 -->
      <div class="flight_control middle_bezel">
        <div class="Bjcolor">
          <div class="head">
            <div class="head_text">飞控服务器</div>
          </div>
          <!-- 内容 -->
          <div class="database">
            <flightControlServer :five="flight" :vessel="vessel"></flightControlServer>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务器弹框 -->
    <el-dialog :visible.sync="dialogVisible" width="566px" :before-close="close_times">
      <div class="_server_dialog">
        <serverDialog
          @serverClose="serverClose"
          :serverData="serverData"
          :servername="servername"
          :interchangerData="interchangerData"
          :Memory="Memory"
          :flow="flow"
          :item="item"
          ref="child"
          :key="numJs"
        ></serverDialog>
      </div>
    </el-dialog>
    <!-- 交换机弹框 -->
    <el-dialog :visible.sync="jiaohuanji" width="566px">
      <div class="_server_dialog">
        <exchangS
          :serverData="serverData"
          :interchangerData="interchangerData"
          :Memory="Memory"
          :flow="flow"
          @serverClose="serverClose"
          :PlatformContainer="PlatformContainer"
        ></exchangS>
      </div>
    </el-dialog>
    <!-- 基站弹框 -->
    <el-dialog :visible.sync="interchanger" width="566px">
      <div class="interchanger">
        <switchDialog @serverClose="serverClose" :flow="flow" :liuchu="liuchu"></switchDialog>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import data from "./data"
//核心网服务器
import kernel_ from "./kernel/index.vue";
// 地图服务器
import mapServer from "./map_server/index.vue";
// 容载服务器
import seatService from "./Seat_service/index.vue";
// 基站
import baseStation from "./base_station";
// 无人机网交换机
import uavWang from "./uav/index.vue";
// 网闸
import wangzha from "./Network_gateway_service/index.vue";
// 图像网交换机
import tuxiangwnag from "./Image_network_switch/index.vue";
// 平台服务器
import platformServr from "./Platform_server/index.vue";
// mqtt 服务器
import mqttServer from "./MQTTserver/index.vue";
// 视频服务器
import videoServer from "./video_server/index.vue";
// 飞控服务器
import flightControlServer from "./Flight_control_server/index.vue";
// 服务器弹框
import serverDialog from "./server_dialog/index.vue";
// 交换机弹框
import exchangS from "./exchange/index.vue";
// 基站
import switchDialog from "./switch/index.vue";
// 引入服务器总览接口
import API from "@/api/modules/fuwuqi.js";
export default {
  components: {
    kernel_,
    mapServer,
    seatService,
    baseStation,
    uavWang,
    wangzha,
    tuxiangwnag,
    platformServr,
    mqttServer,
    videoServer,
    flightControlServer,
    serverDialog,
    switchDialog,
    exchangS
  },
  data() {
    return {
      ...data,
      dialogVisible: false, //服务器弹框开关
      interchanger: false, //基站弹框开关
      jiaohuanji: false, //交换机弹框开关
      interchangerData: null, //交换机数据
      serverData: null, //服务器弹框数据(cpu)
      servername: null, //服务器名称
      Memory: null, //服务器内存数据
      flow: null, //服务器流量数据(流出)
      ip: "116.62.122.225:9100", //查询服务器的ip
      five: null, //平台网数据
      PLATFORMIp: null, //平台网ip
      liuchu: null, //网速(流入)
      vessel: {
        rongqi: 0, //容器总数量
        GreenContainer: 0, //有用容器
        redContainer: 0 //失效容器
      },
      PlatformContainer: [], //平台服务器所有容器
      jiqun: null, //平台服务器机群
      map: null, //地图服务器
      video: null, //视频服务器
      bei: null, //平台服务器缩放倍数
      item: null, //弹框当前服务器的数据
      numJs: 0, // 刷新计数 每次弹框打开都是新的弹框
      core: null, //核心服务器，
      capacity: null, //容栽服务器
      gatekeeper: null, //网闸
      mtt: null, //mqtt服务器
      flight: null //飞控服务器
    };
  },
  mounted() {
    this.zonglan();
    // this.getCpu()
    // this.getVesselCpu()
  },
  methods: {
    // 服务器弹窗
    // 传值 类型 1:核心网,2:地图,3:容载,4:网闸,5:平台,6:mqtt,7:视频,8:飞控,9无人机,10图像网
    server_status(num, index) {
      //打开弹窗
      this.dialogVisible = true;
      this.numJs++;
      // 清空数据
      this.item = null;
      // 判定那个弹窗,进行数据请求
      if (num == 1) {
        this.servername = "核心网服务器";
        let a = this.jiqun[0];
        this.ip = a.id;
        this.item = a;
      } else if (num == 2) {
        this.servername = "地图服务器";
        // 获取平台服务器下那个服务器
        let a = this.map;
        // 重新赋ip地址
        this.ip = a.id;
        // 把当前点击服务器的数据传入弹框
        this.item = a;
      } else if (num == 3) {
        this.servername = "容载服务器";
        let a = this.jiqun[1];
        this.ip = a.id;
        this.item = a;
      } else if (num == 4) {
        this.servername = "网闸";
        let a = this.jiqun[2];
        this.ip = a.id;
        this.item = a;
      } else if (num == 5) {
        this.servername = "平台服务器";
        // 获取平台服务器下那个服务器
        let a = this.jiqun[index];
        // 重新赋ip地址
        this.ip = a.id;
        // 把当前点击服务器的数据传入弹框
        this.item = a;
        this.receptacle("PLATFORM"); //容器名称
        // this.ip = this.PLATFORMIp
      } else if (num == 6) {
        this.servername = "MQTT服务器";
        let a = this.video;
        this.ip = a.id;
        this.item = a;
      } else if (num == 7) {
        this.servername = "视频服务器";
        // 获取平台服务器下那个服务器
        let a = this.video;
        // 重新赋ip地址
        this.ip = a.id;
        // 把当前点击服务器的数据传入弹框
        this.item = a;
      } else if (num == 8) {
        this.servername = "飞控服务器";
        let a = this.map;
        this.ip = a.id;
        this.item = a;
      } else if (num == 9) {
        this.servername = "无人机网交换机";
        this.getCpu();
        this.getMemory();
        this.getFlow();
        this.ip = "116.62.122.225:9100";
      } else if (num == 10) {
        this.servername = "图像网交换机";
        this.getCpu();
        this.getMemory();
        this.getFlow();
        this.ip = "116.62.122.225:9100";
      }
    },

    // 基站弹窗
    jizhan() {
      this.interchanger = true;
      this.getliuchu();
      this.getFlow();
      this.ip = "116.62.122.225:9100";
    },
    // 获取容器
    async receptacle(name) {
      // 重置容器
      this.PlatformContainer = [];
      // 重新请求容器名称
      let res = await API.getServer();
      res.forEach(res => {
        if (res.type == name) {
          this.PlatformContainer = res.container;
        }
      });
    },

    //服务器总览请求
    async zonglan() {
      this.jiqun = [];
      // let res = await API.getServer();
      let res = this.platlist
      // if(res.length == 5){
      //     this.bei = 0.6
      // }
      // 收集ip
      let a = res[0].container; //获取容器信息
      this.vessel.rongqi = a.length;
      this.core = res[0];
      (this.capacity = res[1]), //容栽服务器
        (this.gatekeeper = res[2]), //网闸
        (this.mtt = res[3]), //mqtt服务器
        (this.flight = res[4]), //飞控服务器
        console.log(res, "机群数据");
      res.forEach((item, index) => {
        if (item.type == "PLATFORM") {
          // 平台服务器
          // 对相应的服务器发送数据
          this.five = item;
          this.jiqun.push(item);
          // 收集IP地址
          this.PLATFORMIp = item.id;

          // 集群缩小倍数
          if (this.jiqun.length < 3) {
            this.bei = 0;
          } else if (this.jiqun.length == 5) {
            this.bei = 0.6;
          }
        } else if (item.type == "MAP") {
          this.map = item;
        } else if (item.type == "VIDEO") {
          this.video = item;
        }
      });

      a.forEach(res => {
        if (res.up == true) {
          this.vessel.GreenContainer = this.vessel.GreenContainer + 1;
        } else {
          this.vessel.redContainer = this.vessel.redContainer + 1;
        }
      });
      // console.log(a,this.green, '基础数据');
    },
    // 网络宽带流入
    async getliuchu() {
      var currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getInflux(
        {
          end: Date.now() / 1000, //获取当前时间戳
          start: currentTime2 / 1000, //前一小时时间
          step: 7
        },
        this.ip
      );
      this.liuchu = a.data.result[0].values;
    },
    // 单独cpu详情
    async getCpu() {
      var currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getServerCpu(
        {
          end: Date.now() / 1000, //获取当前时间戳
          start: currentTime2 / 1000, //前一小时时间
          step: 7
        },
        this.ip
      );
      this.serverData = a.data.result[0].values;
    },
    // 单独内存详情
    async getMemory() {
      var currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getServerMemory(
        {
          end: Date.now() / 1000, //获取当前时间戳
          start: currentTime2 / 1000, //前一小时时间
          step: 7
        },
        this.ip
      );
      this.Memory = a.data.result[0].values;
    },
    // 单独流量监控
    async getFlow() {
      var currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getServerflux(
        {
          end: Date.now() / 1000, //获取当前时间戳
          start: currentTime2 / 1000, //前一小时时间
          step: 7
        },
        this.ip
      );
      this.flow = a.data.result[0].values;
    },
    // 关闭定时器
    serverClose() {
      this.dialogVisible = false;
      this.interchanger = false;
      this.jiaohuanji = false;
      // 位置'./server_dialog
      this.$refs.child.close_timer();
    },
    close_times() {
      this.dialogVisible = false;
      this.interchanger = false;
      this.jiaohuanji = false;
      this.$refs.child.close_timer();
    },
    async jump() {
      // let data = {
      //   user: "admin",
      //   password: "mmcmmc123"
      // }
      // let res = await API.jump(data)
      // let str = res.split(";")
      // let str2 = str[0].split("=")
      // console.log(str2);
      // let str3 = str2[1]
      // console.log(str3);
      // document.cookie = "grafana_session" +  "=" + str3
      // window.open("https://32.128.6.52:3003/?orgId=1&sessionId="+str3,"_blank");
      window.open("http://32.128.6.52:3002/login","_blank");
    },
  },
  beforedestroy() {
    // 关闭总览页定时器
    // clearInterval(zongTime)
  }
};
</script>

<style lang="scss" scoped>
.server {
  width: 100%;
  height: 100%;
  height: 100vh;
  // margin: 132px 0 0 0;
  padding: 35px 0 34px 20px;
  position: relative;
  background: #000;
  z-index: 9;
  box-sizing: border-box;
  background-image: url("~@/assets/server/bj.png");
  background-size: 100% 100%;
}

.neirong {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
}

// 头部文字
.head_text {
  font-size: 22px;
  font-family: YouSheBiaoTiHei;
  color: #ffffff;
  line-height: 30px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5),
    0px 0px 12px rgba(19, 145, 241, 0.5);
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 36%, #54acff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 43px;
  padding: 0 16px 0 16px;
  box-sizing: border-box;

  .decide {
    display: flex;

    .icon_svg {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-size: 100% 100%;
      margin: 0 2px 0 0;
    }

    .normal {
      background-image: url("~@/assets/server/正常.svg");
    }

    .abnormal {
      background-image: url("~@/assets/server/异常.svg");
    }

    .lan {
      font-size: 16px !important;
      font-family: D-DIN-Bold, D-DIN !important;
      font-weight: bold !important;
      color: #29cc40 !important;
      margin: 0 10px 0 0;
    }

    .hong {
      height: 18px !important;
      font-size: 16px !important;
      font-family: D-DIN-Bold, D-DIN !important;
      font-weight: bold !important;
      color: #cc2929 !important;
    }
  }
}

.database {
  width: 100%;
  height: calc(100% - 43px);
  background: rgba(21, 95, 192, 0.1);
}

// 头部文字

// 高度自适应
@media all and (min-height: 1000px) {
  .middle_bezel {
    width: 238px !important;
    height: 228px !important;
  }

  .large_frame {
    width: 272px !important;
    height: 310px !important;
  }

  .small_box {
    width: 180px !important;
    height: 160px !important;
  }

  // 核心服务器
  .kernel {
    left: 382px !important;
  }

  // 地图服务器
  .map_server {
    left: 1078px !important;
    top: 0;
  }

  // MQTT服务器
  .mqtt {
    left: 382px !important;
    bottom: 0px !important;
  }

  // 视频服务器
  .video {
    left: 1078px !important;
  }

  // 竖起来的水管
  .one {
    height: 200px !important;
  }

  .two {
    height: 200px !important;
  }

  .three {
    height: 200px !important;
  }

  .eight {
    height: 300px !important;
  }

  .nine {
    height: 300px !important;
  }

  .ten {
    height: 300px !important;
  }

  .eleven {
    height: 322px !important;
  }

  .twelve {
    height: 427px !important;
  }
}

// 水管样式
.pipeline {
  display: flex;
  justify-content: space-between;
  width: 27px;
  position: absolute;
  z-index: 6;

  .up {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_up {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      transform: rotate(180deg);
      animation: xia 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .down {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_down {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      animation: shang 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 水管样式--斜
.pipeline_oblique {
  display: flex;
  justify-content: space-between;
  width: 27px;
  position: absolute;
  z-index: 6;
  transform: rotate(45deg);

  .up {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_up {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      transform: rotate(180deg);
      animation: xia 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .down {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_down {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      animation: shang 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.pipeline_inclined {
  display: flex;
  justify-content: space-between;
  width: 27px;
  position: absolute;
  z-index: 6;
  transform: rotate(135deg);

  .up {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_up {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      transform: rotate(180deg);
      animation: xia 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .down {
    width: 30px;
    height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/up.png");
    background-size: 100% 100%;
    position: relative;

    .d_down {
      position: absolute;
      left: -2px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      animation: shang 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 水管样式---横
.pipeline_across {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 27px;
  position: absolute;
  z-index: 6;

  .up {
    height: 30px;
    width: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/right.png");
    background-size: 100% 100%;
    position: relative;

    .d_left {
      position: absolute;
      left: 0px;
      top: -44px;
      width: 18px;
      height: 100px;
      z-index: 99;
      transform: rotate(270deg);
      animation: zuo 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .down {
    height: 30px;
    width: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-image: url("~@/assets/server/left.png");
    background-size: 100% 100%;

    .d_right {
      position: absolute;
      left: 0px;
      top: -30px;
      width: 18px;
      height: 100px;
      z-index: 99;
      transform: rotate(90deg);
      animation: you 3s linear infinite;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 左移动画
@keyframes zuo {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

// 右移动画
@keyframes you {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

// 下移动画
@keyframes xia {
  0% {
    top: 100%;
  }

  100% {
    top: -100%;
  }
}

// 上移动画
@keyframes shang {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}

// 水管位置
.one {
  left: calc(382px + 238px / 2 - 14px);
  top: 220px;
  height: 96px;
}

.two {
  left: calc(1078px + 238px / 2 - 14px);
  top: 220px;
  height: 96px;
}

.three {
  left: calc(1426px + 238px / 2 - 14px);
  top: 220px;
  height: 96px;
}

.four {
  left: 260px;
  top: calc(50% - 14px);
  width: 200px;
}

.five {
  left: 578px;
  top: calc(50% - 14px);
  width: 200px;
}

.six {
  left: 939px;
  top: calc(50% - 14px);
  width: 200px;
}

.seven {
  left: 1258px;
  top: calc(50% - 14px);
  width: 200px;
}

.eight {
  left: calc(382px + 238px / 2 - 14px);
  top: 476px;
  height: 96px;
}

.nine {
  left: calc(1078px + 238px / 2 - 14px);
  top: 476px;
  height: 96px;
}

.ten {
  left: calc(1426px + 238px / 2 - 14px);
  top: 476px;
  height: 96px;
}

.eleven {
  left: 1025px;
  top: 140px;
  height: 236px;
  transform: rotate(135deg);
}

.twelve {
  left: 1025px;
  top: 425px;
  height: 236px;
  transform: rotate(45deg);
}
.elevenTwo {
  right: 350px;
  top: 425px;
  height: 236px;
  transform: rotate(135deg);
}

.twelveTwo {
  right: 350px;
  top: 140px;
  height: 236px;
  transform: rotate(45deg);
}

// 宽高
.middle_bezel {
  width: 260px;
  height: 228px;
  background: #000;
  z-index: 20;

  .Bjcolor {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/server/kuangOne.png");
    background-size: 100% 100%;
  }
}

.large_frame {
  width: 272px;
  height: 310px;
  background: #000;
  z-index: 20;

  .Bjcolor {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/server/kuangTwo.png");
    background-size: 100% 100%;
  }
}

.small_box {
  width: 180px;
  height: 186px;
  background: #000;
  z-index: 20;

  .Bjcolor {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/server/kuangOne.png");
    background-size: 100% 100%;
  }
}

// 核心数据库
.kernel {
  position: absolute;
  left: 382px;
  top: 0;
}

// 地图服务器
.map_server {
  position: absolute;
  left: 1078px;
  top: 0;
}

// 容载服务器
.Should_the_load {
  position: absolute;
  left: 728px;
  top: 0;
}

// 21座4G基站
.base_station {
  position: absolute;
  left: 0px;
  top: calc(50% - 155px);

  .head {
    height: 36px !important;
  }

  .database {
    width: 100%;
    height: calc(100% - 36px);
  }
}

// 无人机交换网
.uav {
  position: absolute;
  left: 411px;
  top: calc(50% - 93px);

  .head {
    height: 31px !important;
  }

  .database {
    width: 100%;
    height: calc(100% - 31px);
  }
}

// 网闸
._Gatekeeper {
  position: absolute;
  left: 730px;
  top: calc(50% - 114px);
}

// 图像网交换机
.exchange {
  position: absolute;
  left: 1107px;
  top: calc(50% - 93px);

  .head {
    height: 31px !important;
  }

  .database {
    width: 100%;
    height: calc(100% - 31px);
  }
}
// 平台服务器机群
.fleet {
  width: 238px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
}
// 平台服务器
.platform {
  position: absolute;
  left: 1426px;
  top: 0;
  .suofang {
    // transform: scale(.5)
    zoom: 0.6;
  }
}

// MQTT服务器
.mqtt {
  position: absolute;
  left: 382px;
  bottom: 0px;
}

// 视频服务器
.video {
  position: absolute;
  left: 1078px;
  bottom: 0px;
}

// 飞控服务器
.flight_control {
  position: absolute;
  left: 728px;
  bottom: 0px;
}

// 弹框样式
// 服务器弹框高度
._server_dialog {
  background: rgba($color: #000000, $alpha: 0.4);
  height: 761px;
  // border: 1px solid #000;
}

// 交换机弹框高度
.interchanger {
  height: 609px;
  border: none;
}

/deep/ .el-dialog {
  background: transparent;
}
/deep/.el-dialog__wrapper{
  background: rgba($color: #000000, $alpha: 0.6);
}
/deep/ .el-dialog__header {
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__headerbtn {
  display: none;
}
</style>
<style lang="scss">
.v-modal {
  z-index: 0 !important;
}
</style>