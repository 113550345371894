export default{
  platlist: [
    {
        "id": "32.128.6.46:9102",
        "type": "VIDEO",
        "primaryServer": null,
        "name": "fc3d882686b8",
        "ip": "32.128.6.46",
        "memoryUtilization": 61.80556841090565,
        "cpuUtilization": 30.003331115420934,
        "diskUtilization": null,
        "networkTxBps": 1366.9406798489224,
        "networkRxBps": 100.79982226171961,
        "container": []
    },
    {
        "id": "32.128.6.48:9102",
        "type": "PLATFORM",
        "primaryServer": "master",
        "name": "de5f32b96fbc",
        "ip": "32.128.6.48",
        "memoryUtilization": 48.64098531482668,
        "cpuUtilization": 27.756666664034128,
        "diskUtilization": null,
        "networkTxBps": 1443.7777777777776,
        "networkRxBps": 65.53333333333333,
        "container": []
    },
    {
        "id": "32.128.6.49:9102",
        "type": "MAP",
        "primaryServer": null,
        "name": "180848ccebda",
        "ip": "32.128.6.49",
        "memoryUtilization": 32.45775341465524,
        "cpuUtilization": 2.1666666655801237,
        "diskUtilization": null,
        "networkTxBps": 1165.3333333333333,
        "networkRxBps": 100.82222222222222,
        "container": []
    },
    {
        "id": "32.128.6.51:9102",
        "type": "PLATFORM",
        "primaryServer": null,
        "name": "acf1d3e3ca0f",
        "ip": "32.128.6.51",
        "memoryUtilization": 46.90144769271322,
        "cpuUtilization": -1.9693473961766443,
        "diskUtilization": null,
        "networkTxBps": 1313.61523134824,
        "networkRxBps": 60.37192827097631,
        "container": []
    },
    {
        "id": "32.128.6.52:9102",
        "type": "PLATFORM",
        "primaryServer": null,
        "name": "18a42424879a",
        "ip": "32.128.6.52",
        "memoryUtilization": 20.045083178394187,
        "cpuUtilization": 5.337500000000546,
        "diskUtilization": null,
        "networkTxBps": 1350.7333333333333,
        "networkRxBps": 45.53333333333333,
        "container": []
    }
]
}