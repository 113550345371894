<!-- 视频 服务器 -->
<template>
  <div class="onthefull jianju">
    <div class="onthefull">
      <div class="whale">
        <jing :container="container"></jing>
      </div>
      <div class="content">
        <div class="onthefull flexs">
          <div class="img"></div>
          <div class="datas">
            <div class="onthefull flexs shuju">
              <el-tooltip content="网速" placement="top">
                <div class="flexs">
                  <div class="_icons one"></div>
                  <div class="fontOne">{{ wangsu || 1366.94 }}</div>
                  <div class="fontOne juli">kbs</div>
                </div>
              </el-tooltip>
              <el-tooltip content="cpu" placement="top">
                <div class="flexs">
                  <div class="_icons two"></div>
                  <div class="fontOne">{{ cpu || 30.00 }}%</div>
                </div>
              </el-tooltip>
              <el-tooltip content="内存" placement="top">
                <div class="flexs">
                  <div class="_icons three"></div>
                  <div class="fontOne">{{ neicun || 61.81 }}%</div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//引入鲸鱼
import jing from "@/pages/server_details/whale/index.vue";
export default {
  components: {
    jing,
  },
  props: {
    five: {
      type: Object,
    },
    vessel: {
      type: Object,
    },
  },
  data() {
    return {
      neicun: null,
      wangsu: null,
      cpu: null,
      container: null,
    };
  },
  watch: {
    five: function (vla, old) {
      // console.log(vla, '组件里');
      this.cpu = vla.cpuUtilization.toFixed(2);
      this.neicun = vla.memoryUtilization.toFixed(2);
      this.wangsu = vla.networkTxBps.toFixed(2);
      this.container = vla.container;
    },
    vessel: {
      immediate: true,
      handler(vla, old) {
        // this.container = vla
      },
    },
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.jianju {
  padding: 13px 0 24px 24px;
}

.onthefull {
  width: 100%;
  height: 100%;
}

// 鲸鱼
.whale {
  margin: 0 0 13px 0;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
}

.content {
  width: 100%;
  height: calc(100% - 58px);
  padding: 0 0 24px 0;
  box-sizing: border-box;
}

.flexs {
  display: flex;
}

.img {
  width: 88px;
  height: 100%;
  background-image: url("~@/assets/server/serverOne.png");
  background-size: 100% 100%;
}

.datas {
  flex: 1;
  height: 100%;
  padding: 0 0 0 17px;
  box-sizing: border-box;
}

.shuju {
  flex-direction: column;
  justify-content: space-between;
}

._icons {
  width: 17.78px;
  height: 17.78px;
  background-size: 100% 100%;
  margin: 0 7px 0 0;
}

.one {
  background-image: url("~@/assets/server/wangluo.png");
}

.two {
  background-image: url("~@/assets/server/xinpian.png");
}

.three {
  background-image: url("~@/assets/server/neicun.png");
}

.fontOne {
  font-size: 14px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #a0b4ba;
}
.juli {
  margin: 0 0 0 6px;
}
</style>