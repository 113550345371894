<!-- 21座1.4G基站 -->
<template>
    <div class="onthefull jizhan">
        <div class="flexs">
            <div class="flexs item-center">
                <div class="icons one"></div>
                <div class="fontOne">网络状态</div>
            </div>
            <div style="width:23px;"></div>
            <div class="flexs item-center">
                <div class="icons two"></div>
                <div class="fontOne">供网状态</div>
            </div>
        </div>
        <div class="_icon">

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.onthefull {
    width: 100%;
    height: 100%;
}

.jizhan {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 0 24px 0;
    box-sizing: border-box;
}

.flexs {
    display: flex;
}

.item-center {
    align-items: center;
}

.icons {
    width: 18px;
    height: 18px;
    margin: 0 7px 0 0;
    background-size: 100% 100%;
}

.one {
    background-image: url('~@/assets/server/wangluo.png');
}

.two {
    background-image: url('~@/assets/server/jizahn.png');
}
._icon{
    flex-grow: 1;
    width: 150px;
    margin: 9px 0 0 0;
    background-image: url('~@/assets/server/xinhaota.png');
    background-size: 100% 100%;
}
.fontOne {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #A0B4BA;
}
</style>