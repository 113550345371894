<!-- 鹰控 各个 服务器 弹框 -->
<template>
  <div class="onthefull">
    <div class="_server onthefull">
      <!-- 头部 -->
      <div class="pup_head">
                <div class="flexs">
                   <div class="pup_img">
                        <img
                            src="~@/assets/zong_lan_ye_img/jinye.png"
                            style="width: 100%; height: 100%"
                            alt=""
                        />
                    </div>
                    <div class="fontOne">{{ servername }}状态情况</div>
                </div>
                <div class="fontTwo" @click="_serverClose">关闭</div>
            </div>
      <!-- 第一行 -->
      <div class="cpu_state">
        <!-- cpu -->
        <div class="flexs">
          <div class="_icon one"></div>
          <div class="wenzi">
            <div class="fontOne huanhang">{{ newestCPU }}%</div>
            <div class="fontTwo">cpu</div>
          </div>
        </div>
        <!-- 内存 -->
        <div class="flexs">
          <div class="_icon two"></div>
          <div class="wenzi">
            <div class="fontOne huanhang">{{ newestMemory }}T</div>
            <div class="fontTwo">内存</div>
          </div>
        </div>
        <!-- 流量 -->
        <div class="flexs">
          <div class="_icon three"></div>
          <div class="wenzi">
            <div class="fontOne huanhang">
              {{ (newstflow / 1024).toFixed(2) }}kB/<span
                style="font-size: 16px"
                >s</span
              >
            </div>
            <div class="fontTwo">流量</div>
          </div>
        </div>
      </div>
      <!-- 第二行 -->
      <div class="server_state">
        <div class="fangkuai"></div>
        <div class="tabulation">
          <div class="flexs hang tou">
            <div>名称</div>
            <div>状态</div>
            <div>内存占用</div>
            <div>内存占比</div>
            <div>CPU占比</div>
          </div>
          <div class="shen">
            <div
              class="flexs tr-td"
              v-for="(item, index) in rongqi"
              :key="index"
            >
              <div>
                {{
                  item.name.length > 8
                    ? item.name.slice(0, 7) + "..."
                    : item.name
                }}
              </div>
              <div v-if="item.up == true">正常</div>
              <div v-else style="color: red">异常</div>
              <div>{{ vesslememory[index] }}GB</div>
              <div>{{ vessleNet[index] }}%</div>
              <div>{{ vessleCPu[index] }}%</div>
            </div>
          </div>
        </div>
      </div>
      <!-- echarts -->
      <div class="diagram flexs">
        <!-- cpu图表 -->
        <div class="flexs _height">
          <div class="echarts_style flexs">
            <div class="_icons _one"></div>
            <div class="_font">
              <div>CPU</div>
              <div>状态</div>
            </div>
          </div>
          <div id="CPU"></div>
        </div>
        <!-- 内存图表 -->
        <div class="flexs _height">
          <div class="echarts_style flexs">
            <div class="_icons _two"></div>
            <div class="_font">
              <div>内存</div>
              <div>状态</div>
            </div>
          </div>
          <div id="memory"></div>
        </div>
        <!-- 流量图表 -->
        <div class="flexs _height">
          <div class="echarts_style flexs">
            <div class="_icons _three"></div>
            <div class="_font">
              <div>流量</div>
              <div>状态</div>
            </div>
          </div>
          <div id="flux"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入服务器总览接口
import API from "@/api/modules/fuwuqi.js";
export default {
  props: {
    serverData: {
      //cpu数据
      type: Array,
    },
    servername: {
      //弹框名称
      type: String,
    },
    Memory: {
      //内存数据
      type: Array,
    },
    flow: {
      // 流量数据
    },
    PlatformContainer: {
      // 容器地址信息
      type: Array,
    },
    item: {
      // 总览数据
      type: Object,
    },
  },
  data() {
    return {
      information: null, //CPU的数据
      // servername: null,//服务器名称
      times: [], //图标里x轴时间(echarts)cpu
      times_: [], //图标hover下的时间(echarts)cpu
      Cpushuju: [], //cpu百分比数据(echarts)cpu
      newestCPU: null, //cpu最新数据
      CPUname: "", //服务器名
      newestMemory: null, //内存最新数据
      Memorytime: [], //图标里x轴时间(echarts)内存
      Memorytime_: [], //图标hover下的时间(echarts)内存
      Memoryshuju: [], //内存百分比数据(echarts)内存
      MemoryName: "", //服务器内存名
      MemoryGB: null, //内存单位GB
      flowtime: null, ///图标里x轴时间(echarts)流量
      flowtime_: null, //图标hover下的时间(echarts)流量
      flowshuju: [], //流量百分比数据(echarts)流量
      flowName: "", //流量名
      newstflow: null, // 流量最新数据
      cpuTime: null, //cpu定时器,
      ramTime: null, //内存定时器
      shedTime: null, //流量定时器(流出)
      rongqiArr: [], //存储容器和地址
      instance: "", //存储当前容器路径
      containerName: "", //存储当前容器名
      shuju: null, //存储总览页接口数据
      rongqi: [], //容器数据
      vessle:null,//容器数据存放
      vessleCPu:[],//容器cpu数据
      vesslememory:[],// 容器内存量，
      vessleNet:[],// 容器内存利用率
     
    };
  },
  watch: {
    item: {
      // 实例尚未创建所以第一次点击没有效果
      // 解决方案:立即执行一遍immediate: true,
      immediate: true,
      handler(vla, old) {
        // 图表数据
        this.shuju = vla;
        vla.container.forEach((item) => {
          if (item.up == true) {
            this.rongqi.push(item);
          }
        });
        vla.container.forEach((item) => {
          if (item.up != true) {
            this.rongqi.push(item);
          }
        });
        this.getVessle(this.rongqi);
        this.getCpu();
        this.get_memory();
        this.outflow();
      },
    },

    // Memory: function (vla, old) {
    //     this.Memorytime = []
    //     this.Memoryshuju = []
    //     this.Memorytime_ = []
    //     // 内存数据摘出
    //     this.internal_storage(vla)
    //     this.ram(vla)
    //     this.memory_echarts()
    // },
    // flow: function (vla, old) {
    //     // console.log(vla, '触发了吗');
    //     this.flowtime = []
    //     this.flowtime_ = []
    //     this.flowshuju = []
    //     // 流量数据摘出
    //     this.flux_time(vla)
    //     this.flux_data(vla)
    //     this.flux_echarts()
    // },
    // 直接查询一个容器
    // PlatformContainer: async function (vla, old) {
    //     // console.log(vla, '查地址');
    //     this.times = []
    //     this.Cpushuju = []
    //     this.times_ = []
    //     this.rongqiArr = vla
    //     this.instance = vla[0].instance
    //     this.containerName = vla[0].name
    //     await this.getCpu()
    //     await this.get_memory()
    //     await this.outflow()

    // },

    deep: true,
  },
  mounted() {},
  methods: {
    // 容器查询数据
    async getVessle(rongqi) {
        // 获取当前时间时间戳
        let xianzai = new Date();
        let b = xianzai.getTime();
        // 获取一小时前时间戳
        let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
        let c = await API.getVessel(
          {
            end: b / 1000,
            start: currentTime2 / 1000,
            step: 6,
          },
          rongqi[0].instance,
          rongqi[0].name
        ); //这边传的地址和容器名
        console.log(c,'sdadasdasd');
      rongqi.forEach(async (item) => {
       // 获取当前时间时间戳
        let xianzai = new Date();
        let b = xianzai.getTime();
        // 获取一小时前时间戳
        let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
        let a = await API.getVessel(
          {
            end: b / 1000,
            start: currentTime2 / 1000,
            step: 6,
          },
          item.instance,
          item.name
        ); //这边传的地址和容器名
       let cpu = a.cpu[a.cpu.length - 1]
       this.vessleCPu.push(Number(cpu[1]).toFixed(2))
       let memory = a.memory[a.memory.length - 1]
       this.vesslememory.push(Number((memory[1] / 1024 / 1024 / 1024)).toFixed(2))
       let net = a.net[a.net.length - 1]
       this.vessleNet.push(Number(net[1]).toFixed(2))
       console.log(net,'net');
      });
      console.log(this.vessleCPu,this.vesslememory,this.vessleNet,'CPu，memory，net');
    },
    // 获取服务器cpu数据
    async getCpu() {
      // 防止重复点击，造成数组数据变多
      this.times = [];
      this.Cpushuju = [];
      this.times_ = [];
      // console.log(instance, containerName, '地址ip名');
      this.cpuTime = setInterval(async () => {
        // 清空状态
        this.times = [];
        this.Cpushuju = [];
        this.times_ = [];
        // 现在的时间戳
        let xianzai = new Date();
        let b = xianzai.getTime();
        // 获取一小时前时间戳
        let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
        let a = await API.getServerCpu(
          {
            end: b / 1000,
            start: currentTime2 / 1000,
            step: 6,
          },
          this.shuju.id
        ); //这边传的地址和容器名
        let x = a.data.result[0].values;
        this.CPUname = a.data.resultType;
        this.zCPU(x);
        this.jutishuju(x);
        this.cpu_echarts();
      }, 2000);

      /* 第一次执行 **/
      // 获取当前时间时间戳
      let xianzai = new Date();
      let b = xianzai.getTime();
      // 获取一小时前时间戳
      let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getServerCpu(
        {
          end: b / 1000,
          start: currentTime2 / 1000,
          step: 6,
        },
        this.shuju.id
      ); //这边传的地址和容器名
      let x = a.data.result[0].values;
      this.CPUname = a.data.resultType;
      this.zCPU(x);
      this.jutishuju(x);
      this.cpu_echarts();
    },

    // 监听服务器器内存
    async get_memory() {
      // 防止重复点击，造成数组数据变多
      this.Memorytime = [];
      this.Memoryshuju = [];
      this.Memorytime_ = [];
      this.ramTime = setInterval(async () => {
        // 清空状态
        this.Memorytime = [];
        this.Memoryshuju = [];
        this.Memorytime_ = [];
        // 现在的时间戳
        let xianzai = new Date();
        let b = xianzai.getTime();
        // 获取一小时前时间戳
        let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
        let a = await API.getMemory(
          {
            end: b / 1000,
            start: currentTime2 / 1000,
            step: 6,
          },
          this.shuju.id
        ); //这边传的地址和容器名
        let x = a.data.result[0].values;
        this.MemoryName = a.data.resultType;
        this.ram(x);
        this.internal_storage(x);
        this.memory_echarts();
      }, 2000);

      /* 第一次执行 **/
      // 获取当前时间时间戳
      let xianzai = new Date();
      let b = xianzai.getTime();
      // 获取一小时前时间戳
      let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getMemory(
        {
          end: b / 1000,
          start: currentTime2 / 1000,
          step: 6,
        },
        this.shuju.id
      ); //这边传的地址和容器名
      let x = a.data.result[0].values;
      this.MemoryName = a.data.resultType;
      this.ram(x);
      this.internal_storage(x);
      this.memory_echarts();
    },

    // 服务器流量(流出)
    async outflow() {
      // 防止重复点击，造成数组数据变多
      this.flowtime = [];
      this.flowtime_ = [];
      this.flowshuju = [];
      this.shedTime = setInterval(async () => {
        // 清空状态
        this.flowtime = [];
        this.flowtime_ = [];
        this.flowshuju = [];
        // 现在的时间戳
        let xianzai = new Date();
        let b = xianzai.getTime();
        // 获取一小时前时间戳
        let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
        let a = await API.getServerflux(
          {
            end: b / 1000,
            start: currentTime2 / 1000,
            step: 6,
          },
          this.shuju.id
        ); //这边传的地址和容器名
        let x = a.data.result[0].values;
        this.flowName = a.data.resultType;
        this.flux_time(x);
        this.flux_data(x);
        this.flux_echarts();
      }, 2000);
      /* 第一次执行 **/
      // 获取当前时间时间戳
      let xianzai = new Date();
      let b = xianzai.getTime();
      // 获取一小时前时间戳
      let currentTime2 = new Date().setHours(new Date().getHours() - 1); // 1小时前
      let a = await API.getServerflux(
        {
          end: b / 1000,
          start: currentTime2 / 1000,
          step: 6,
        },
        this.shuju.id
      ); //这边传的地址和容器名
      let x = a.data.result[0].values;
      this.flowName = a.data.resultType;
      this.flux_time(x);
      this.flux_data(x);
      this.flux_echarts();
    },
    // 时间戳转换具体时间
    _time(time = +new Date()) {
      // console.log(time);
      var date = new Date(time + 8 * 3600 * 1000);
      return date.toJSON().substr(0, 19).replace("T", " ");
    },
    // cpu图表
    cpu_echarts() {
      //
      let a = 0;
      let b = false;
      const myChart = this.$echarts.init(document.getElementById("CPU"));
      let option = {
        backgroundColor: "transparent",
        title: {
          text: "",
          textStyle: {
            align: "center",
            color: "#fff",
            fontSize: 20,
          },
          top: "5%",
          left: "center",
        },
        tooltip: {
          show: true,
          trigger: "item",
          showContent: true, //关闭原有样式
          padding: [
            0, // 上
            0, // 右
            0, // 下
            0, // 左
          ],
          formatter: (name) => {
            // 这里forEach循环就不创建样式,所以改成这样
            if (name.name == this.times[0]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[0]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[0]}%</div></div></div></div>`;
            } else if (name.name == this.times[1]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[1]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[1]}%</div></div></div></div>`;
            } else if (name.name == this.times[2]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[2]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[2]}%</div></div></div></div>`;
            } else if (name.name == this.times[3]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[3]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[3]}%</div></div></div></div>`;
            } else if (name.name == this.times[4]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[4]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[4]}%</div></div></div></div>`;
            } else if (name.name == this.times[5]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[5]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.CPUname}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[5]}%</div></div></div></div>`;
            }
          },
        },
        grid: {
          top: "15%",
          left: "15%",
          right: "5%",
          bottom: "20%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#C7E5E7",
              },
              symbol: ["none", "arrow"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
              symbolSize: [5, 5], // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: "14",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              //隐藏刻度线
              show: false,
            },
            boundaryGap: true,
            data: this.times,
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              formatter: function (value) {
                if (b == false) {
                  let c = a;
                  a = a + 1;
                  b = true;
                  return c + "%";
                } else {
                  a = a * 2;
                  return a + "%";
                }
              },
              textStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },
            // data: ['A', 'B', 'C', 'D', 'E', 'F'],
          },
        ],
        series: [
          {
            name: "bar",
            type: "line",
            smooth: false, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 8, //内圆大小
            emphasis: {
              itemStyle: {
                borderWidth: 20,
              },
            },
            lineStyle: {
              normal: {
                color: "#BDCFD2", //线条颜色
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "#00ca95",
              },
            },

            itemStyle: {
              color: "#24EC40", //内圆颜色
              borderColor: "rgb(177,220,227,.34)", //外圆颜色
              borderWidth: "6", //外圆大小,请加上内圆
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 112,
              shadowOffsetX: 1112,
            },
            data: this.Cpushuju,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 内存图表
    memory_echarts() {
      //
      let a = 0;
      let b = false;
      const myChart = this.$echarts.init(document.getElementById("memory"));
      let option = {
        backgroundColor: "transparent",
        title: {
          text: "",
          textStyle: {
            align: "center",
            color: "#fff",
            fontSize: 20,
          },
          top: "5%",
          left: "center",
        },
        tooltip: {
          show: true,
          trigger: "item",
          showContent: true, //关闭原有样式
          padding: [
            0, // 上
            0, // 右
            0, // 下
            0, // 左
          ],
          formatter: (name) => {
            if (name.name == this.Memorytime[0]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[0]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[0]}T</div></div></div></div>`;
            } else if (name.name == this.Memorytime[1]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[1]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[1]}T</div></div></div></div>`;
            } else if (name.name == this.Memorytime[2]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[2]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[2]}T</div></div></div></div>`;
            } else if (name.name == this.Memorytime[3]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[3]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[3]}T</div></div></div></div>`;
            } else if (name.name == this.Memorytime[4]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[4]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[4]}T</div></div></div></div>`;
            } else if (name.name == this.Memorytime[5]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.Memorytime_[5]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #FFC200;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.MemoryName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Memoryshuju[5]}T</div></div></div></div>`;
            }
          },
        },
        grid: {
          top: "15%",
          left: "15%",
          right: "5%",
          bottom: "20%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#C7E5E7",
              },
              symbol: ["none", "arrow"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
              symbolSize: [5, 5], // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: "14",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              //隐藏刻度线
              show: false,
            },
            boundaryGap: true,
            data: this.Memorytime,
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // formatter: function (value) {
              //     if (b == false) {
              //         let c = a
              //         a = a + 1
              //         b = true
              //         return c + '%'
              //     } else {
              //         a = a * 2
              //         return a + '%'
              //     }
              // },
              textStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },
            // data: ['A', 'B', 'C', 'D', 'E', 'F'],
          },
        ],
        series: [
          {
            name: "bar",
            type: "line",
            smooth: false, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 8, //内圆大小
            emphasis: {
              itemStyle: {
                borderWidth: 20,
              },
            },
            lineStyle: {
              normal: {
                color: "#BDCFD2", //线条颜色
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "#00ca95",
              },
            },

            itemStyle: {
              color: "#FFC200", //内圆颜色
              borderColor: "rgb(177,220,227,.34)", //外圆颜色
              borderWidth: "6", //外圆大小,请加上内圆
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 112,
              shadowOffsetX: 1112,
            },
            data: this.Memoryshuju,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 流量图表
    flux_echarts() {
      //
      let a = 0;
      let b = false;
      const myChart = this.$echarts.init(document.getElementById("flux"));
      let option = {
        backgroundColor: "transparent",
        title: {
          text: "",
          textStyle: {
            align: "center",
            color: "#fff",
            fontSize: 20,
          },
          top: "5%",
          left: "center",
        },
        tooltip: {
          show: true,
          trigger: "item",
          showContent: true, //关闭原有样式
          padding: [
            0, // 上
            0, // 右
            0, // 下
            0, // 左
          ],
          formatter: (name) => {
            if (name.name == this.flowtime[0]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[0]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[0]}B</div></div></div></div>`;
            } else if (name.name == this.flowtime[1]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[1]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[1]}B</div></div></div></div>`;
            } else if (name.name == this.flowtime[2]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[2]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[2]}B</div></div></div></div>`;
            } else if (name.name == this.flowtime[3]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[3]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[3]}B</div></div></div></div>`;
            } else if (name.name == this.flowtime[4]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[4]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowName}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[4]}B</div></div></div></div>`;
            } else if (name.name == this.flowtime[5]) {
              return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${
                this.flowtime_[5]
              }</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${"cadvisor"}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${
                this.flowshuju[5]
              }B</div></div></div></div>`;
            }
          },
        },
        grid: {
          top: "15%",
          left: "15%",
          right: "5%",
          bottom: "20%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#C7E5E7",
              },
              symbol: ["none", "arrow"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
              symbolSize: [5, 5], // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: "14",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              //隐藏刻度线
              show: false,
            },
            boundaryGap: true,
            data: this.flowtime,
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // formatter: function (value) {
              //     if (b == false) {
              //         let c = a
              //         a = a + 1
              //         b = true
              //         return c + '%'
              //     } else {
              //         a = a * 2
              //         return a + '%'
              //     }
              // },
              textStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },
            // data: ['A', 'B', 'C', 'D', 'E', 'F'],
          },
        ],
        series: [
          {
            name: "bar",
            type: "line",
            smooth: false, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 8, //内圆大小
            emphasis: {
              itemStyle: {
                borderWidth: 20,
              },
            },
            lineStyle: {
              normal: {
                color: "#BDCFD2", //线条颜色
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "#00EAFF",
              },
            },

            itemStyle: {
              color: "#00EAFF", //内圆颜色
              borderColor: "rgb(177,220,227,.34)", //外圆颜色
              borderWidth: "6", //外圆大小,请加上内圆
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 112,
              shadowOffsetX: 1112,
            },
            data: this.flowshuju,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // cpu时间
    zCPU(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 开始时间
      let one = vla[0][0];
      let _one = this._time(one * 1000).slice(10, 16);
      this.times.push(_one);
      this.times_.push(this._time(one * 1000));
      // 数组长度20%的时间
      let two = vla[a][0];
      let _two = this._time(two * 1000).slice(10, 16);
      this.times.push(_two);
      this.times_.push(this._time(two * 1000));
      // 数组长度40%的时间
      let three = vla[b][0];
      let _three = this._time(three * 1000).slice(10, 16);
      this.times.push(_three);
      this.times_.push(this._time(three * 1000));
      // 数组长度60%的时间
      let four = vla[c][0];
      let _four = this._time(four * 1000).slice(10, 16);
      this.times.push(_four);
      this.times_.push(this._time(four * 1000));
      // 数组长度80%的时间
      let five = vla[d][0];
      let _five = this._time(five * 1000).slice(10, 16);
      this.times.push(_five);
      this.times_.push(this._time(five * 1000));
      // 当前时间
      let six = vla[vla.length - 1][0];
      let _six = this._time(six * 1000).slice(10, 16);
      this.times.push(_six);
      this.times_.push(this._time(six * 1000));
      // console.log(this.times_, 'zCPU');
    },
    // 内存时间
    internal_storage(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 开始时间
      let one = vla[0][0];
      let _one = this._time(one * 1000).slice(10, 16);
      this.Memorytime.push(_one);
      this.Memorytime_.push(this._time(one * 1000));
      // 数组长度20%的时间
      let two = vla[a][0];
      let _two = this._time(two * 1000).slice(10, 16);
      this.Memorytime.push(_two);
      this.Memorytime_.push(this._time(two * 1000));
      // 数组长度40%的时间
      let three = vla[b][0];
      let _three = this._time(three * 1000).slice(10, 16);
      this.Memorytime.push(_three);
      this.Memorytime_.push(this._time(three * 1000));
      // 数组长度60%的时间
      let four = vla[c][0];
      let _four = this._time(four * 1000).slice(10, 16);
      this.Memorytime.push(_four);
      this.Memorytime_.push(this._time(four * 1000));
      // 数组长度80%的时间
      let five = vla[d][0];
      let _five = this._time(five * 1000).slice(10, 16);
      this.Memorytime.push(_five);
      this.Memorytime_.push(this._time(five * 1000));
      // 当前时间
      let six = vla[vla.length - 1][0];
      let _six = this._time(six * 1000).slice(10, 16);
      this.Memorytime.push(_six);
      this.Memorytime_.push(this._time(six * 1000));
    },
    // CPU具体数据
    jutishuju(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 数组第一条数据
      let one = vla[0][1];
      let _one = Number(one).toFixed(2);
      this.Cpushuju.push(_one);
      // 数组长度20%的数据
      let two = vla[a][1];
      let _two = Number(two).toFixed(2);
      this.Cpushuju.push(_two);
      // 数组长度40%的数据
      let three = vla[b][1];
      let _three = Number(three).toFixed(2);
      this.Cpushuju.push(_three);
      // 数组长度60%的数据
      let four = vla[c][1];
      let _four = Number(four).toFixed(2);
      this.Cpushuju.push(_four);
      // 数组长度80%的数据
      let five = vla[d][1];
      let _five = Number(five).toFixed(2);
      this.Cpushuju.push(_five);
      // 当前时间数据
      let six = vla[vla.length - 1][1];
      let _six = Number(six).toFixed(2);
      this.newestCPU = _six;
      this.Cpushuju.push(_six);

      // console.log(this.Cpushuju,'jjsd');
    },
    // 内存具体数据
    ram(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 数组第一条数据
      let one = vla[0][1];
      let _one = (Number(one) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_one);
      // 数组长度20%的数据
      let two = vla[a][1];
      let _two = (Number(two) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_two);
      // 数组长度40%的数据
      let three = vla[b][1];
      let _three = (Number(three) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_three);
      // 数组长度60%的数据
      let four = vla[c][1];
      let _four = (Number(four) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_four);
      // 数组长度80%的数据
      let five = vla[d][1];
      let _five = (Number(five) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_five);
      // 当前时间数据
      let six = vla[vla.length - 1][1];
      let _six = (Number(six) / 1024 / 1024 / 1024 / 1024).toFixed(2);
      this.newestMemory = _six;
      this.MemoryGB = (Number(six) / 1024 / 1024 / 1024).toFixed(2);
      this.Memoryshuju.push(_six);
    },
    // 流量时间
    flux_time(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 开始时间
      let one = vla[0][0];
      let _one = this._time(one * 1000).slice(10, 16);
      this.flowtime.push(_one);
      this.flowtime_.push(this._time(one * 1000));
      // 数组长度20%的时间
      let two = vla[a][0];
      let _two = this._time(two * 1000).slice(10, 16);
      this.flowtime.push(_two);
      this.flowtime_.push(this._time(two * 1000));
      // 数组长度40%的时间
      let three = vla[b][0];
      let _three = this._time(three * 1000).slice(10, 16);
      this.flowtime.push(_three);
      this.flowtime_.push(this._time(three * 1000));
      // 数组长度60%的时间
      let four = vla[c][0];
      let _four = this._time(four * 1000).slice(10, 16);
      this.flowtime.push(_four);
      this.flowtime_.push(this._time(four * 1000));
      // 数组长度80%的时间
      let five = vla[d][0];
      let _five = this._time(five * 1000).slice(10, 16);
      this.flowtime.push(_five);
      this.flowtime_.push(this._time(five * 1000));
      // 当前时间
      let six = vla[vla.length - 1][0];
      let _six = this._time(six * 1000).slice(10, 16);
      this.flowtime.push(_six);
      this.flowtime_.push(this._time(six * 1000));
    },
    // 流量具体数据
    flux_data(vla) {
      let a = parseInt((vla.length - 1) * 0.2);
      let b = parseInt((vla.length - 1) * 0.4);
      let c = parseInt((vla.length - 1) * 0.6);
      let d = parseInt((vla.length - 1) * 0.8);
      // 数组第一条数据
      let one = vla[0][1];
      let _one = Number(one).toFixed(2);
      this.flowshuju.push(_one);
      // 数组长度20%的数据
      let two = vla[a][1];
      let _two = Number(two).toFixed(2);
      this.flowshuju.push(_two);
      // 数组长度40%的数据
      let three = vla[b][1];
      let _three = Number(three).toFixed(2);
      this.flowshuju.push(_three);
      // 数组长度60%的数据
      let four = vla[c][1];
      let _four = Number(four).toFixed(2);
      this.flowshuju.push(_four);
      // 数组长度80%的数据
      let five = vla[d][1];
      let _five = Number(five).toFixed(2);
      this.flowshuju.push(_five);
      // 当前时间数据
      let six = vla[vla.length - 1][1];
      let _six = Number(six).toFixed(2);
      this.newstflow = _six;
      this.flowshuju.push(_six);
    },
    // 关闭弹窗和定时器
    _serverClose() {
      // 调用父组件的serverClose方法
      this.$emit("serverClose");
    },
    close_timer() {
      clearInterval(this.cpuTime);
      this.cpuTime = null;
      clearInterval(this.ramTime);
      this.ramTime = null;
      clearInterval(this.shedTime);
      this.shedTime = null;
    },
  },
  beforedestroy() {
    clearInterval(this.cpuTime);
    clearInterval(this.ramTime);
    clearInterval(this.shedTime);
    this.shedTime = null;
    this.cpuTime = null;
    this.ramTime = null;
  },
};
</script>

<style lang="scss" scoped>
.onthefull {
  width: 100%;
  height: 100%;
}

._server {
  border-radius: 10px 10px 0px 0px;
  background: rgba(8,26,57,0.8);
  // background-image: url("~@/assets/server/dakuang.png") no-repeat;
  background-size: 100% 100%;
  backdrop-filter: blur(4px);
}

.flexs {
  display: flex;
}

// 头部
.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
  }
}

// 第一行状态栏
.cpu_state {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;

  ._icon {
    width: 52px;
    height: 52px;
    background-size: 100% 100%;
  }

  .one {
    background-image: url("~@/assets/server/xinpian.png");
  }

  .two {
    background-image: url("~@/assets/server/neicun.png");
  }

  .three {
    background-image: url("~@/assets/server/GIS-TL.png");
  }

  .wenzi {
    // margin: 0 0 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .fontOne {
    font-size: 28px;
    font-family: D-DIN-Bold, D-DIN;
    font-weight: bold;
    color: #ffffff;
  }

  .fontTwo {
    font-size: 13px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
  }
}

// 第二行
.server_state {
  width: 100%;
  height: 145px;
  display: flex;
  align-items: center;
    padding : 0 15px;

  .fangkuai {
    width: 51px;
    height: 119px;
    background: rgba(21, 95, 192, 0.1);
    border: 1px solid;
    border-image: linear-gradient(
        270deg,
        rgba(171, 210, 234, 1),
        rgba(115, 166, 209, 0)
      )
      1 1;
    margin: 0 23px 0 0;
  }

  .tabulation {
    width: 520px;
    height: 125px;
    display: flex;
    flex-direction: column;
    padding: 0 25px 0 25px;
    box-sizing: border-box;

    .hang {
      height: 35px;
      justify-content: space-around;
      align-items: center;
      div {
        flex: 1;
        text-align: center;
      }
    }

    .tou {
      background: rgba(138, 152, 177, 0.18);
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }

    .shen {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      flex: 1;
      overflow-y: auto;
    }
  }
}

.diagram {
  width: 100%;
  height: 480px;
  padding: 23px 24px 24px 24px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;

  .echarts_style {
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0 16px 0 0;
    justify-content: center;

    ._icons {
      width: 52px;
      height: 52px;
      background-size: 100% 100%;
      margin: 0 0 6px 0;
    }

    ._font {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }

    ._one {
      background-image: url("~@/assets/server/xinpian.png");
    }

    ._two {
      background-image: url("~@/assets/server/neicun.png");
    }

    ._three {
      background-image: url("~@/assets/server/GIS-TL.png");
    }
  }
}

#CPU {
  flex: 1;
  height: 100%;
  pointer-events: auto;
}

#flux {
  flex: 1;
  height: 100%;
}

#memory {
  flex: 1;
  height: 100%;
}

._height {
  height: 120px;
}
.huanhang {
  white-space: nowrap;
}
.tr-td {
  justify-content: space-around;
  div {
    flex: 1;
    text-align: center;
  }
}
</style>