<!-- 无人机网交换机 -->
<template>
  <div class="onthefull place">
    <div class="_icons">

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
    .onthefull{
        width: 100%;
        height: 100%;
    }
    .place{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ._icons{
        width: 58px;
        height: 102px;
        background-size: 100% 100%;
        background-image: url('~@/assets/server/serverTwo.png');
    }
</style>