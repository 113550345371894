
<!-- 每个格子里的鲸鱼行 -->
<template>
  <div class="onthefull flexs">
    <el-tooltip content="容器总数量" placement="top">
      <div class="fish"></div>
    </el-tooltip>
    <div class="amount">
      <span class="fontOne">{{ '0' }}</span>
      <span style="width:3px;"></span>
      <span class="fontTwo">个</span>
    </div>
    <div class="correct">
      <el-tooltip content="容器启动数量" placement="top">
        <div class="gou"></div>
      </el-tooltip>
      <span style="width:3px;"></span>
      <div>
        <span class="fontThree">{{ '0' }}</span>
        <span class="fontFour">个</span>
      </div>
    </div>
    <div class="mistake">
      <el-tooltip content="容器异常数量" placement="top">
        <div class="cuo"></div>
      </el-tooltip>
      <span style="width:3px;"></span>
      <div>
        <span class="fontThree">{{ '0' }}</span>
        <span class="fontFour">个</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    container: {
      type: Object || Array,
    },
  },
  methods: {
    dui(res) {
      let a = [];
      res.forEach((item) => {
        if (item.up == true) {
          a.push(item);
        }
      });
      return a.length;
    },
    cuo(res) {
      let a = [];
      res.forEach((item) => {
        if (item.up != true) {
          a.push(item);
        }
      });
      return a.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.onthefull {
  width: 100%;
  height: 100%;
}

.flexs {
  display: flex;
  align-items: center;
}

.fish {
  width: 32px;
  height: 32px;
  background-image: url("~@/assets/server/jingyu.png");
  background-size: 100% 100%;
}

.amount {
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
}

.fontOne {
  font-size: 24px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #a0b4ba;
}

.fontTwo {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #a0b4ba;
}

.correct {
  margin: 0 0 0 14px;
  display: flex;
  align-items: center;
}

.gou {
  width: 14px;
  height: 14px;
  background-image: url("~@/assets/server/zheng.png");
  background-size: 100% 100%;
  margin: 0 6px 0 0;
}

.fontThree {
  font-size: 14px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #a0b4ba;
}

.fontFour {
  font-size: 8px;
  font-family: MicrosoftYaHei;
  color: #a0b4ba;
}
.mistake {
  margin: 0 0 0 14px;
  display: flex;
  align-items: center;
  .cha {
    width: 14px;
    height: 14px;
    background-image: url("~@/assets/server/zheng.png");
    background-size: 100% 100%;
    margin: 0 6px 0 0;
  }
  .cuo {
    width: 14px;
    height: 14px;
    background-image: url("~@/assets/server/error.png");
    background-size: 100% 100%;
    margin: 0 6px 0 0;
  }
}
</style>